import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, VStack, Heading, Button } from "@chakra-ui/react";

const Dashboard = ({ children }) => {
  return (
    <Flex>
      <Box width="250px" bg="gray.100" height="100vh" p={4}>
        <VStack spacing={4} align="stretch">
          <Heading as="h2" size="lg">Dashboard</Heading>
          <Button as={Link} to="/dashboard" colorScheme="blue">Product List</Button>
          <Button as={Link} to="/dashboard/add" colorScheme="green">Add Product</Button>
          <Button as={Link} to="/dashboard/urls" colorScheme="purple">Manage URLs</Button>
        </VStack>
      </Box>
      <Box flex={1} p={8}>
        {children}
      </Box>
    </Flex>
  );
};

export default Dashboard;
