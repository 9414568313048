import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, List, ListItem, IconButton, HStack, useToast } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const UrlList = ({ urls, onAddUrl, onDeleteUrl }) => {
  const [newUrl, setNewUrl] = useState('');
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddUrl(newUrl);
    setNewUrl('');
    toast({
      title: "URL Added",
      description: "The URL has been successfully added.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box maxWidth="500px">
      <VStack spacing={4} align="stretch">
        <Heading as="h2" size="xl">Manage URLs</Heading>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel htmlFor="newUrl">New URL</FormLabel>
            <Input
              type="url"
              id="newUrl"
              value={newUrl}
              onChange={(e) => setNewUrl(e.target.value)}
              placeholder="https://www.amazon.com.tr/dp/..."
              required
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" mt={4}>
            Add URL
          </Button>
        </form>
        <List spacing={3}>
          {urls.map((url, index) => (
            <ListItem key={index}>
              <HStack justify="space-between">
                <Box>{url}</Box>
                <IconButton
                  aria-label="Delete URL"
                  icon={<DeleteIcon />}
                  onClick={() => onDeleteUrl(url)}
                  colorScheme="red"
                />
              </HStack>
            </ListItem>
          ))}
        </List>
      </VStack>
    </Box>
  );
};

export default UrlList;
