import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Link, Text } from "@chakra-ui/react";

const ProductList = ({ products }) => {
  return (
    <Box>
      <Heading as="h2" size="xl" mb={4}>Product List</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Price</Th>
            <Th>Stock Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product, index) => (
            <Tr key={index}>
              <Td>{product.productTitle}</Td>
              <Td>{product.price}</Td>
              <Td>{product.stockStatus}</Td>
              <Td>
                <Link href={product.url} isExternal color="blue.500">
                  View on Amazon
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {products.length === 0 && (
        <Text mt={4}>No products found. Add some products to get started.</Text>
      )}
    </Box>
  );
};

export default ProductList;
