import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProductList from './components/ProductList';
import UrlList from './components/UrlList';
import { ChakraProvider, CSSReset, Box } from "@chakra-ui/react";
import theme from './theme';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [products, setProducts] = useState([]);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProducts();
      fetchUrls();
    }
  }, [isAuthenticated]);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://stock.zeng3451.workers.dev/api/products');
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchUrls = async () => {
    try {
      const response = await fetch('https://stock.zeng3451.workers.dev/api/urls');
      if (response.ok) {
        const data = await response.json();
        setUrls(data);
      }
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };

  const handleLogin = (username, password) => {
    if (username === 'admin' && password === 'password') {
      setIsAuthenticated(true);
    }
  };

  const handleAddUrl = async (newUrl) => {
    try {
      const response = await fetch('https://stock.zeng3451.workers.dev/api/urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: newUrl }),
      });
      if (response.ok) {
        fetchUrls();
      }
    } catch (error) {
      console.error('Error adding URL:', error);
    }
  };

  const handleDeleteUrl = async (urlToDelete) => {
    try {
      const response = await fetch('https://stock.zeng3451.workers.dev/api/urls', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: urlToDelete }),
      });
      if (response.ok) {
        fetchUrls();
      }
    } catch (error) {
      console.error('Error deleting URL:', error);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box minHeight="100vh" bg="gray.50">
        <Router>
          <Routes>
            <Route path="/login">
              {isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />}
            </Route>
            <Route path="/dashboard">
              {isAuthenticated ? (
                <Dashboard>
                  <Route exact path="/dashboard" render={() => <ProductList products={products} />} />
                  <Route path="/dashboard/urls" render={() => <UrlList urls={urls} onAddUrl={handleAddUrl} onDeleteUrl={handleDeleteUrl} />} />
                </Dashboard>
              ) : (
                <Navigate to="/login" />
              )}
            </Route>
            <Navigate from="/" to="/login" />
          </Routes>
        </Router>
      </Box>
    </ChakraProvider>
  );
};

export default App;
